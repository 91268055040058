import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


function loggedInRedDash(to, from, next) {
  if(localStorage.jwt) {
    next('/')
  } else {
    next()
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.jwt) {
    next()
  } else {
    next('/login')
  }
}


const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    name: "home",
    beforeEnter: isLoggedIn 
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    name:"login", 
    beforeEnter: loggedInRedDash
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('../views/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
